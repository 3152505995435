import { ReactWrapper } from '$pages/common/react-wrapper';
import SitelistmapFullscreen, {
  ISitelistmapFullscreenProps
} from './sitelistmap-fullscreen.react';

export class SitelistPage extends ReactWrapper<
  ISitelistmapFullscreenProps,
  void
> {
  constructor() {
    super(SitelistmapFullscreen, params => ({
      params
    }));
  }
}
