import MapComponent from '$components/map/map.react';
import { MuiModalCard } from '$components/modals/mui-modal-card/mui-modal-card';
import { IControllerMapMarker, IMapMarker } from '$interfaces/application';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { WindowProxyWithSetMarkers } from '$pages/sitelistpage-react/modules/sitelistmap/sitelistmap.react';
import { CardContent } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

export interface ISitelistmapFullscreenProps { }

const SitelistmapFullscreen: FC<ISitelistmapFullscreenProps> = () => {
  const [refrenceToSitelistLost, setRefrenceToSitelistLost] = useState(false)
  const [markers, setMarkers] = useState<IMapMarker[]>([])
  const [t] = useCaseInsensitiveTranslation()

  const sitelistWindow = window.opener;

  (window as unknown as WindowProxyWithSetMarkers).setMarkers = function (m: IMapMarker[]) {
    if (m.length !== markers.length ) { 
      setMarkers(m)
    }
  }

  setInterval(() => {
    if (!sitelistWindow || !sitelistWindow.getMarkers) {
      setRefrenceToSitelistLost(true)
    }
  }, 1000);


  useEffect(() => {
    if (!sitelistWindow?.getMarkers) return
    const markersFromSitelist = sitelistWindow.getMarkers()
    setMarkers(markersFromSitelist)
  }, [sitelistWindow])

  function markerClicked(marker: IControllerMapMarker, e?: MouseEvent) {
    if (marker.siteId && e?.ctrlKey) {
      return sitelistWindow.open(`sitedetails/${marker.siteId}`)
    }
    
    if (marker.siteId) {      
      return sitelistWindow.navigate(`sitedetails/${marker.siteId}`)
    }
  }

  return (
    <div style={{ height: "100%" }}>
      {refrenceToSitelistLost && <MuiModalCard headerText='Connection lost' open><CardContent>{t("ui_sitelistmap_fullscreen_open_via_sitelist")}</CardContent></MuiModalCard>}
      <MapComponent className="fill-height"
        name='fullscreen-sitelistmap'
        onClick={markerClicked}
        clustered
        autoBounds
        markers={markers}>
      </MapComponent>
    </div>
  );
};

export default SitelistmapFullscreen;